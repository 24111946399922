
import { defineComponent } from 'vue'
import IOPagePerformancePacing from '@/components/IOPage/PerformanceTab/IOPagePerformancePacing.vue'
import IOPagePerformanceKpiToOptimize from '@/components/IOPage/PerformanceTab/IOPagePerformanceKpiToOptimize.vue'
import IOPagePerformanceSecondaryKpi from '@/components/IOPage/PerformanceTab/IOPagePerformanceSecondaryKpi.vue'
import IOPagePerformanceConstraints from '@/components/IOPage/PerformanceTab/IOPagePerformanceConstraints.vue'
import IOPagePerformanceImpressionsAndCurrency from '@/components/IOPage/PerformanceTab/IOPagePerformanceImpressionsAndCurrency.vue'
import * as ioPageUtils from '../../../../utils/ioPageUtils'
import { KpiInterfaceWithType, OverviewData, PerformanceData, PerformancePeriod } from '../../../../types/io_page_types'
import { DspInstruction, IoBrief } from '../../../../types/instruction_type'

export default defineComponent({
  name: 'IOPagePerformance',
  components: {
    IOPagePerformanceImpressionsAndCurrency,
    IOPagePerformanceConstraints,
    IOPagePerformanceSecondaryKpi,
    IOPagePerformanceKpiToOptimize,
    IOPagePerformancePacing },
  props: ['dsp', 'loading'],
  data () {
    return {
    }
  },
  methods: {
    setPeriod (period: string) {
      this.$store.commit('setPerformancePeriod', period as PerformancePeriod)
    },
    formatDateWithTimezone (date: Date, tz: number): string {
      const dateWithTimezone = ioPageUtils.getDateWithTzOffset(date, tz)
      return ioPageUtils.formatDatetime(dateWithTimezone)
    }
  },
  computed: {
    period (): PerformancePeriod {
      return this.$store.getters.getPerformancePeriod
    },
    ioBrief (): IoBrief<DspInstruction> {
      return this.$store.getters.getIoBrief
    },
    performance (): PerformanceData {
      return this.$store.getters.getPerformance
    },
    kpiFromPeriod (): KpiInterfaceWithType {
      return this.$store.getters.getKpiFromPeriod
    },
    kpiFromPreviousPeriod (): KpiInterfaceWithType {
      return this.$store.getters.getKpiFromPreviousPeriod
    },
    overview (): OverviewData {
      return this.$store.getters.getOverview
    },
    getInfo (): TextValueType<string>[] {
      return [
        {
          text: 'Yesterday',
          value: (this.performance?.kpi_interfaces?.yesterday) ? `from "${this.formatDateWithTimezone(this.performance.kpi_interfaces.yesterday.start_period_tz, this.performance.kpi_interfaces.yesterday.tz_offset)}" to "${this.formatDateWithTimezone(this.performance.kpi_interfaces.yesterday.end_period_tz, this.performance.kpi_interfaces.yesterday.tz_offset)}"` : 'N/A'
        },
        {
          text: 'Yesterday minus 1 (comparison)',
          value: (this.performance?.kpi_interfaces?.yesterday_minus_1) ? `from "${this.formatDateWithTimezone(this.performance.kpi_interfaces.yesterday_minus_1.start_period_tz, this.performance.kpi_interfaces.yesterday_minus_1.tz_offset)}" to "${this.formatDateWithTimezone(this.performance.kpi_interfaces.yesterday_minus_1.end_period_tz, this.performance.kpi_interfaces.yesterday_minus_1.tz_offset)}"` : 'N/A'
        },
        {
          text: 'Last 7 days',
          value: (this.performance?.kpi_interfaces?.last_7_days) ? `from "${this.formatDateWithTimezone(this.performance.kpi_interfaces.last_7_days.start_period_tz, this.performance.kpi_interfaces.last_7_days.tz_offset)}" to "${this.formatDateWithTimezone(this.performance.kpi_interfaces.last_7_days.end_period_tz, this.performance.kpi_interfaces.last_7_days.tz_offset)}"` : 'N/A'
        },
        {
          text: 'Last 7 days minus 1 (comparison)',
          value: (this.performance?.kpi_interfaces?.last_7_days_minus_1) ? `from "${this.formatDateWithTimezone(this.performance.kpi_interfaces.last_7_days_minus_1.start_period_tz, this.performance.kpi_interfaces.last_7_days_minus_1.tz_offset)}" to "${this.formatDateWithTimezone(this.performance.kpi_interfaces.last_7_days_minus_1.end_period_tz, this.performance.kpi_interfaces.last_7_days_minus_1.tz_offset)}"` : 'N/A'
        },
        {
          text: 'Since beginning of flight',
          value: (this.performance?.kpi_interfaces?.flight) ? `from "${this.formatDateWithTimezone(this.performance.kpi_interfaces.flight.start_period_tz, this.performance.kpi_interfaces.flight.tz_offset)}" to "${this.formatDateWithTimezone(this.performance.kpi_interfaces.flight.end_period_tz, this.performance.kpi_interfaces.flight.tz_offset)}"` : 'N/A'
        },
        {
          text: 'Since beginning of flight minus 1 (comparison)',
          value: (this.performance?.kpi_interfaces?.flight_minus_1) ? `from "${this.formatDateWithTimezone(this.performance.kpi_interfaces.flight_minus_1.start_period_tz, this.performance.kpi_interfaces.flight_minus_1.tz_offset)}" to "${this.formatDateWithTimezone(this.performance.kpi_interfaces.flight_minus_1.end_period_tz, this.performance.kpi_interfaces.flight_minus_1.tz_offset)}"` : 'N/A'
        }
      ]
    },
    getLastDataUpdate (): string {
      return this.performance?.kpi_interfaces?.yesterday?.last_client_update_utc ? ioPageUtils.formatDatetime(this.performance?.kpi_interfaces?.yesterday?.last_client_update_utc) : 'N/A'
    },
    lastDataUpdateOutdated (): boolean {
      if (this.getLastDataUpdate === 'N/A') {
        return true
      }
      // if the last data update is older than 24 hours, we consider it outdated
      const oneDayInMilliseconds = 24 * 60 * 60 * 1000
      return this.performance?.kpi_interfaces?.yesterday?.last_client_update_utc ? new Date().getTime() - new Date(this.performance?.kpi_interfaces?.yesterday?.last_client_update_utc).getTime() > oneDayInMilliseconds : false
    },
    daysSinceFlightStart (): number {
      return ioPageUtils.compareDateStrings(this.overview?.overview?.billing_start?.toString(), new Date().toString())
    },
    isFlightOver (): boolean {
      if (!this.overview?.overview?.billing_end) {
        return true
      }
      return ioPageUtils.compareDateStrings(new Date().toString(), this.overview?.overview?.billing_end?.toString()) < 0
    },
    beginningOfFlightText (): string {
      return (this.daysSinceFlightStart >= 50 && !this.isFlightOver) ? 'Last 50 days' : 'Since beginning of flight'
    }
  }
})
