
import { defineComponent, PropType } from 'vue'
import { isNotNullOrUndefined } from '../../../../utils/commonUtils'
import CopyClipboardComponent from '@/components/Common/CopyClipboardComponent.vue'
import { formatNumbers } from '../../../../utils/ioPageUtils'
import { PerformanceData, KpiInterfaceWithType } from '../../../../types/io_page_types'
import { DspInstruction, IoBrief } from '../../../../types/instruction_type'
import { ConversionFunnelInstruction, FunnelId } from '../../../../types/brief_type'

export default defineComponent({
  name: 'IOPagePerformanceSecondaryKpi',
  components: { CopyClipboardComponent },
  props: {
    performance: {
      type: Object as PropType<PerformanceData>,
      required: true
    },
    ioBrief: {
      type: Object as PropType<IoBrief<DspInstruction>>,
      required: true
    },
    kpiFromPeriodProp: {
      type: Object as PropType<KpiInterfaceWithType>,
      required: true
    },
    kpiFromPreviousPeriodProp: {
      type: Object as PropType<KpiInterfaceWithType>,
      required: true
    }
  },
  methods: {
    formatNumbers,
    getClassMajorInfo (value: string): string {
      return value?.length > 14 ? 'major-info-sm' : 'major-info'
    }
  },
  computed: {
    kpiFromPeriod (): KpiInterfaceWithType {
      if (this.complementaryKpiType === 'frequency') {
        return this.performance?.kpi_interfaces?.flight
      }
      return this.kpiFromPeriodProp
    },
    kpiFromPreviousPeriod (): KpiInterfaceWithType {
      if (this.complementaryKpiType === 'frequency') {
        return this.performance?.kpi_interfaces?.flight_minus_1
      }
      return this.kpiFromPreviousPeriodProp
    },
    kpiToOptimize (): string {
      return this.kpiFromPeriod?.kpi_to_optimize || this.ioBrief?.KPI_to_optimize || '-'
    },
    complementaryKpiValue (): string {
      return isNotNullOrUndefined(this.kpiFromPeriod?.complementary_kpi_value) ? formatNumbers(this.kpiFromPeriod.complementary_kpi_value) : '-'
    },
    kpiComparedToPrevious (): string {
      return this.formatNumbers(((this.kpiFromPeriod?.complementary_kpi_value - this.kpiFromPreviousPeriod?.complementary_kpi_value) / this.kpiFromPreviousPeriod?.complementary_kpi_value) * 100) + '%'
    },
    kpiCompareIcon (): string {
      const currentValue = this.kpiFromPeriod?.complementary_kpi_value
      const prevValue = this.kpiFromPreviousPeriod?.complementary_kpi_value
      return prevValue < currentValue ? 'arrow_drop_up' : prevValue > currentValue ? 'arrow_drop_down' : 'remove'
    },
    showPixels (): boolean {
      return ['CPA', 'CPA_PC'].includes(this.kpiToOptimize)
    },
    pixels (): ConversionFunnelInstruction {
      return this.ioBrief?.conversion_funnel_pixel_ids || {}
    },
    firstStepPixels (): FunnelId[] {
      return this.pixels['1']
    },
    complementaryKpiType (): string {
      return this.performance?.kpi_interfaces?.flight?.complementary_kpi_type
    },
    complementaryKpiName (): string {
      return this.kpiFromPeriod?.complementary_kpi_display_name || '-'
    },
    fontSize (): string {
      return (this.kpiToOptimize.length > 14 || this.complementaryKpiName.length > 14) ? 'major-info-sm' : 'major-info'
    }
  },
  watch: {
  }
})
