
import { defineComponent } from 'vue'
import { getClassStratLvl } from '../../../../utils/stratLvlUtils'
import { mdiAccount } from '@mdi/js'
import { isNotNullOrUndefined } from '../../../../utils/commonUtils'
import * as ioPageUtils from '../../../../utils/ioPageUtils'
import { Baseline, DspInstruction, IoBrief } from '../../../../types/instruction_type'
import { OverviewData } from '../../../../types/io_page_types'

export default defineComponent({
  name: 'IOPageOverviewSettings',
  methods: {
    getClassStratLvl
  },
  icons: {
    mdiAccount
  },
  computed: {
    insertionOrder (): IoBrief<DspInstruction> {
      return this.$store.getters.getIoBrief
    },
    overview (): OverviewData {
      return this.$store.getters.getOverview
    },
    iconOttoMinViz (): string {
      return this.insertionOrder.otto_min_viz ? 'visibility' : 'visibility_off'
    },

    isAbTest (): boolean {
      return this.overview.baseline &&
          this.overview.baseline.baseline_type === 'ab_test' &&
          this.overview.baseline.ai_performance === 'to_be_defined' &&
          isNotNullOrUndefined(this.overview.baseline.baseline_io) &&
          (ioPageUtils.compareDateStrings(this.overview.baseline.ab_test_start_date, (new Date()).toDateString()) >= 0)
    },
    isAbTestToRate (): boolean {
      return this.isAbTest && (ioPageUtils.compareDateStrings((new Date()).toDateString(), this.overview.baseline.ab_test_end_date) <= 0)
    },
    isAbTestRunning (): boolean {
      return this.isAbTest && (ioPageUtils.compareDateStrings((new Date()).toDateString(), this.overview.baseline.ab_test_end_date) > 0)
    },
    stratLvl (): StratLvl {
      return this.insertionOrder.strat_lvl || 'standard'
    }
  }
})
