
import { defineComponent, PropType } from 'vue'
import { isNotNullOrUndefined } from '../../../../utils/commonUtils'
import * as ioPageUtils from '../../../../utils/ioPageUtils'
import { PerformanceData, PerformancePeriod } from '../../../../types/io_page_types'
import { AnalyticsPacingStatus } from '../../../../types/overview_types'

export default defineComponent({
  name: 'IOPagePerformancePacing',
  props: {
    performance: {
      type: Object as PropType<PerformanceData>,
      required: true
    }
  },
  data () {
    return {
      ioId: this.$route.params.external_id,
      baseMoneyDashboardUrl: 'https://scibids.cloud.looker.com/dashboards/1188',
      baseImpressionDashboardUrl: 'https://scibids.cloud.looker.com/dashboards/1189'
    }
  },
  methods: {
    formatDateToMonthDay (dateStr: string): string {
      const date = new Date(dateStr)
      return ioPageUtils.formatDateToMonthDay(date)
    },
    shortenPacingStatus (status: string): string {
      if (!isNotNullOrUndefined(status)) {
        return 'N/A'
      }
      return status === 'Delivering' ? 'Deliv.' : status === 'Underdelivery' ? 'Under.' : 'Over.'
    },
    getPacingForDate (date: string): AnalyticsPacingStatus {
      return this.pacing.find((pacing: AnalyticsPacingStatus) => ioPageUtils.areDateStringsSameDate(pacing.day_tz.toString(), date))
    },
    getPacingStatusForDate (date: string): string {
      const pacing = this.getPacingForDate(date)
      return pacing ? `${this.shortenPacingStatus(pacing.pacing_status)} - ${Math.round((1 - pacing.io_satu) * 100)}%` : 'N/A'
    },
    getPacingClassForDate (date: string): string {
      const pacing = this.getPacingForDate(date)
      if (isNotNullOrUndefined(pacing)) {
        const status = pacing.pacing_status
        return status === 'Delivering' ? 'delivering' : status === 'Underdelivery' ? 'underdelivery' : 'overdelivery'
      }
      return ''
    }
  },
  computed: {
    period (): PerformancePeriod {
      return this.$store.getters.getPerformancePeriod
    },
    periodIsDaily (): boolean {
      return this.period === 'day' as PerformancePeriod
    },
    periodIsWeekly (): boolean {
      return this.period === 'week' as PerformancePeriod
    },
    periodIsFlight (): boolean {
      return this.period === 'flight' as PerformancePeriod
    },
    pacing (): AnalyticsPacingStatus[] {
      return this.performance?.pacing || []
    },
    dailyPacing (): string {
      // get latest pacing only if latest pacing date is yesterday
      const yesterday = new Date()
      yesterday.setDate(yesterday.getDate() - 1)
      const latest = this.pacing.find((pacing: AnalyticsPacingStatus) => (new Date(pacing.day_tz)).toDateString() === yesterday.toDateString())
      if (isNotNullOrUndefined(latest)) {
        return `${Math.round((1 - latest?.io_satu) * 100)}%`
      }
      return `N/A`
    },
    isDailyPacingUnavailable (): boolean {
      return this.dailyPacing === 'N/A'
    },
    prevDay (): string {
      const twoDaysAgo = new Date()
      twoDaysAgo.setDate(twoDaysAgo.getDate() - 2)
      return twoDaysAgo.toDateString()
    },
    prevDayPacing (): string {
      const prev = this.pacing.find((pacing: AnalyticsPacingStatus) => (new Date(pacing.day_tz)).toDateString() === this.prevDay)
      if (isNotNullOrUndefined(prev)) {
        return `${Math.round((1 - prev?.io_satu) * 100)}%`
      }
      return 'N/A'
    },
    isPrevDayPacingUnavailable (): boolean {
      return this.prevDayPacing === 'N/A'
    },
    lastSevenDaysDates (): string[] {
      // array of dates of the last 7 days
      const lastSevenDays = []
      for (let i = 1; i < 8; i++) {
        const date = new Date()
        date.setDate(date.getDate() - i)
        lastSevenDays.push(date.toDateString())
      }
      return lastSevenDays
    },
    pacingsOfFlight (): AnalyticsPacingStatus[] {
      const startPeriodTz = this.performance?.kpi_interfaces?.flight?.start_period_tz.toString()
      const endPeriodTz = this.performance?.kpi_interfaces?.flight?.end_period_tz.toString()
      const fiftyDaysAgo = new Date()
      fiftyDaysAgo.setDate(fiftyDaysAgo.getDate() - 50)
      if (ioPageUtils.compareDateStrings(startPeriodTz, fiftyDaysAgo.toDateString()) >= 0) { // if flight started over 50 days ago
        return this.pacing.filter((pacing: AnalyticsPacingStatus) => ioPageUtils.compareDateStrings(fiftyDaysAgo.toDateString(), pacing.day_tz.toString()) >= 0)
      }
      return this.pacing.filter((pacing: AnalyticsPacingStatus) => ioPageUtils.compareDateStrings(startPeriodTz, pacing.day_tz.toString()) >= 0 && ioPageUtils.compareDateStrings(pacing.day_tz.toString(), endPeriodTz) >= 0)
    },
    averageFlightPacing (): string {
      const pacing = this.pacingsOfFlight
      // sum of all io_spent / sum of all io_obj
      const sumSpent = pacing.reduce((acc: number, curr: AnalyticsPacingStatus) => acc + curr.io_spent, 0)
      const sumBudget = pacing.reduce((acc: number, curr: AnalyticsPacingStatus) => acc + curr.io_obj, 0)
      return `${Math.floor((sumSpent / sumBudget) * 100)}%`
    },
    numberOfDaysDelivering (): number {
      return this.pacingsOfFlight.filter((pacing: AnalyticsPacingStatus) => pacing.pacing_status === 'Delivering').length
    },
    numberOfDaysUnderdelivering (): number {
      return this.pacingsOfFlight.filter((pacing: AnalyticsPacingStatus) => pacing.pacing_status === 'Underdelivery').length
    },
    numberOfDaysOverdelivering (): number {
      return this.pacingsOfFlight.filter((pacing: AnalyticsPacingStatus) => pacing.pacing_status === 'Overdelivery').length
    },
    dashboardLink (): string {
      if (this.pacing[0]?.type_budget === 'imp') {
        // impression dashboard
        return `${this.baseImpressionDashboardUrl}?Insertion+Order+ID=${this.ioId}&Scibids+Status+%28Surcouche%29=LIVE`
      }
      // money dashboard
      return `${this.baseMoneyDashboardUrl}?Insertion+Order+ID=${this.ioId}&Scibids+Status+%28Surcouche%29=LIVE`
    }
  }
})
