
import { defineComponent, PropType } from 'vue'
import { isNotNullOrUndefined } from '../../../../utils/commonUtils'
import { formatNumbers } from '../../../../utils/ioPageUtils'
import { KpiInterfaceWithType } from '../../../../types/io_page_types'
import { DspInstruction, IoBrief } from '../../../../types/instruction_type'
import { Period } from '../../../../types/brief_enum'

export default defineComponent({
  name: 'IOPagePerformanceConstraints',
  props: {
    ioBrief: {
      type: Object as PropType<IoBrief<DspInstruction>>,
      required: true
    }
  },
  methods: {
    noConstraint (value: string): boolean {
      return value.includes('No constraint')
    },
    formatNumbers
  },
  computed: {
    kpiFromPeriod (): KpiInterfaceWithType {
      return this.$store.getters.getKpiFromPeriod
    },
    kpiFromPreviousPeriod (): KpiInterfaceWithType {
      return this.$store.getters.getKpiFromPreviousPeriod
    },
    displayCPM (): boolean {
      return isNotNullOrUndefined(this.ioBrief?.constraints_io?.max_CPM) || isNotNullOrUndefined(this.ioBrief?.constraints_io?.min_CPM) || isNotNullOrUndefined(this.kpiFromPeriod?.cpm)
    },
    minCPM (): string {
      return isNotNullOrUndefined(this.ioBrief?.constraints_io?.min_CPM) ? formatNumbers(this.ioBrief.constraints_io.min_CPM) : '-'
    },
    maxCPM (): string {
      return isNotNullOrUndefined(this.ioBrief?.constraints_io?.max_CPM) ? formatNumbers(this.ioBrief.constraints_io.max_CPM) : '-'
    },
    minMaxCPM (): string {
      return this.minCPM !== '-' || this.maxCPM !== '-' ? `${this.minCPM} / ${this.maxCPM}` : 'No constraint on CPM'
    },
    displayMinViz (): boolean {
      return isNotNullOrUndefined(this.ioBrief?.obj_watcher?.min_viz?.value) || isNotNullOrUndefined(this.kpiFromPeriod?.viewability)
    },
    minViz (): string {
      return isNotNullOrUndefined(this.ioBrief?.obj_watcher?.min_viz?.value) ? `${this.formatNumbers(this.ioBrief?.obj_watcher?.min_viz?.value * 100)}%` : 'No constraint on viewability'
    },
    displayMargin (): boolean {
      return isNotNullOrUndefined(this.ioBrief?.constraints_io?.margin_daily) || (isNotNullOrUndefined(this.kpiFromPeriod?.margin) && this.kpiFromPeriod?.margin !== 0)
    },
    expectedMargin (): string {
      return isNotNullOrUndefined(this.ioBrief?.constraints_io?.margin_daily) ? `${this.ioBrief?.constraints_io?.margin_daily * 100}%` : 'No constraint on margin'
    },
    displayFrequency (): boolean {
      return isNotNullOrUndefined(this.ioBrief?.max_frequency?.exposures) || isNotNullOrUndefined(this.kpiFromPeriod?.frequency)
    },
    expectedFrequency (): string {
      const freq = this.ioBrief?.max_frequency
      const amount = (isNotNullOrUndefined(freq?.amount) && freq.period !== Period.Lifetime) ? freq.amount + ' ' : ''
      return isNotNullOrUndefined(freq?.exposures) ? `<span>Max <strong>${freq.exposures}</strong> imp(s) per <strong>${amount}${freq.period}</strong></span>` : 'No constraint on frequency'
    },
    cpm (): string {
      return this.kpiFromPeriod?.cpm ? this.formatNumbers(this.kpiFromPeriod.cpm) : '-'
    },
    displayCpmEvolution (): boolean {
      return isNotNullOrUndefined(this.kpiFromPreviousPeriod?.cpm)
    },
    cpmCompareIcon (): string {
      const currentValue = this.kpiFromPeriod?.cpm
      const prevValue = this.kpiFromPreviousPeriod?.cpm
      return (prevValue > currentValue) ? 'arrow_drop_up' : (prevValue < currentValue ? 'arrow_drop_down' : 'remove')
    },
    cpmComparedToPrevious (): string {
      return `${this.formatNumbers((this.kpiFromPreviousPeriod?.cpm - this.kpiFromPeriod?.cpm) / this.kpiFromPreviousPeriod?.cpm * 100, 2)}%`
    },
    isCpmOutOfBounds (): boolean {
      return (this.cpm !== '-' && ((this.minCPM !== '-' && this.cpm < this.minCPM) || (this.maxCPM !== '-' && this.cpm > this.maxCPM)))
    },
    viewability (): string {
      return isNotNullOrUndefined(this.kpiFromPeriod?.viewability) ? `${this.formatNumbers(this.kpiFromPeriod?.viewability * 100)}%` : '-'
    },
    displayViewabilityEvolution (): boolean {
      return isNotNullOrUndefined(this.kpiFromPreviousPeriod?.viewability)
    },
    viewabilityCompareIcon (): string {
      const currentValue = this.kpiFromPeriod?.viewability
      const prevValue = this.kpiFromPreviousPeriod?.viewability
      return prevValue > currentValue ? 'arrow_drop_up' : prevValue < currentValue ? 'arrow_drop_down' : 'remove'
    },
    viewabilityComparedToPrevious (): string {
      return `${this.formatNumbers(((this.kpiFromPreviousPeriod?.viewability - this.kpiFromPeriod?.viewability) / this.kpiFromPreviousPeriod?.viewability), 2)} pts`
    },
    isViewRateUnderExpected (): boolean {
      return !this.noConstraint(this.minViz) && this.viewability !== '-' && this.kpiFromPeriod?.viewability < this.ioBrief?.obj_watcher?.min_viz?.value
    },
    margin (): string {
      return this.kpiFromPeriod?.margin ? this.formatNumbers(this.kpiFromPeriod?.margin * 100) + '%' : '-'
    },
    isMarginUnderExpected (): boolean {
      return !this.noConstraint(this.expectedMargin) && this.margin !== '-' && this.kpiFromPeriod?.margin < this.ioBrief?.constraints_io?.margin_daily
    },
    frequency (): string {
      return this.formatNumbers(this.kpiFromPeriod?.frequency)
    },
    isFrequencyOverExpected (): boolean {
      return !this.noConstraint(this.expectedFrequency) && this.frequency !== '-' && this.kpiFromPeriod?.frequency > this.ioBrief?.max_frequency?.exposures
    }
  }
})
